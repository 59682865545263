
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import XTable from "../../../../components/x-table/x-table.vue";
import { $goodsCategory } from "../services/goods-category";
import { XTableProxy } from "@/components/x-table/table.proxy";
import goodsclassify from "../../goods-list/components/GoodsClassify/goodsclassify.vue";
import goodsDetail from "@/components/GoodsDetail/GoodsDetail.vue";
@Component({
  name: "audit-list",
  components: { XTable, goodsclassify, goodsDetail }
})
export default class AuditList extends Vue {
  isCategoryShow = false;
  @Prop({ required: true }) tableProxy!: XTableProxy;
  @Prop({ required: true }) sellerList!: XTableProxy;
  @PropSync('keywords', { required: true }) propKeywords!: any;
  @Prop({ required: true }) type!: '1' | '0';
  @PropSync('currentTab', { required: true }) propCurrentTab!: '1' | '0';

  category = $goodsCategory.create();
  goodsclassifyData: any = {};
  dialogVisible: any = false;
  mounted() {
    this.$nextTick(() => {
      this.tableProxy.rebuildLayout!();
    });
  }



  opengoodsclassify(row) {
    this.goodsclassifyData = {
      from: 1,
      goods_id: row.goods_id,
      good_sn: row.good_sn,
      platform_profit: row.platform_profit
    }
    this.dialogVisible = true
  }

  handleShow() {
    this.isCategoryShow = !this.isCategoryShow;
    (this.$refs.xTable as any).toggleSearch()
  }

  @Watch('propCurrentTab', { immediate: true })
  propCurrentTabChanged() {
    this.$emit('update:currentTab', this.propCurrentTab);
    this.$forceUpdate();
  }

  @Watch('propKeywords', { immediate: true, deep: true })
  propKeywordsChanged() {
    this.$emit('update:keywords', this.propKeywords);
  }
}
