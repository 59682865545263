
import { Component, Vue } from 'vue-property-decorator';
import XTable from '@/components/x-table/x-table.vue';
import XDialog from '@/components/x-dialog/x-dialog.vue';
import { $xDialog } from '@/components/x-dialog/dialog.proxy';
import {
  $goodsAudit,
  $goodsAuditDetail, $sellerService,
} from '@/pages/goods/goods-audit/services/goods-audit';
import {
  $xTable,
  XTableProxy,
  XTableStdRequestParams,
  XTableStdResponse
} from '@/components/x-table/table.proxy';
import * as $GoodsAPI from "@/api/goods";
import AuditList from "@/pages/goods/goods-audit/components/audit-list.vue";

@Component({
  name: 'goods-audit',
  components: {
    AuditList,
    XTable,
    XDialog
  }
})
export default class GoodsAudit extends Vue {
  multiple = true;
  loading = false;
  currentTab = '1';
  goodsAuditForm = {
    pass: 1,
    message: ''
  };

  goodsAuditRules = {
    message: [
      { required: false, message: "请输入审核备注！", trigger: "blur" },
    ],
  };

  sellerList = $xTable.create({
    key: 'shop_type',
    onRequest(params: XTableStdRequestParams): Promise<XTableStdResponse> {
      return $sellerService.list({
        shop_type: 2,
        page_no: 1,
        page_size: 9999
      })
    }
  });

  keywords = {
    value: '',
    currentKey: 'goods_name',
    keys: [
      ['商品名称', 'goods_name'],
      ['商品编号', 'goods_sn'],
    ],
  };

  dialogTable: XTableProxy = null!;

  dialogProxy = $xDialog.create({
    title: '商品变更记录',
    wrapperClass: 'supplier-goods-selector',
    disableConfirm: true,
    disableCancel: true,
    width: '60vw',
  });

  auditDialog = $xDialog.create({
    title: '批量审核',
    disableConfirm: false,
    disableCancel: false,
    beforeConfirm: this.beforeConfirm,
    width: '40vw',
  });

  tableProxy: XTableProxy = null!;

  created() {
    this.tableProxy = $xTable.create({
      key: 'goods_id',
      search: {
        formData() {
          return {
            goods_sn: '',
            goods_name: '',
            is_auth: 0,
            goods_ptype: '',
            market_enable: '',
            goods_source: 1,
            low_mktprice: undefined,
            high_mktprice: undefined,
            firstCategory: undefined,
            secondCategory: undefined,
            threeCategory: undefined,
            low_supprice: undefined,
            high_supprice: undefined,
          };
        },
        onSortChange(parentSort, prop, order) {
          const _order = order === "ascending" ? 'asc' : 'desc';
          return order ? { name: prop, order: _order } : {};
        },
      },
      onRequest: (params: XTableStdRequestParams): Promise<XTableStdResponse> => {
        this.loading = true;

        this.keywords.keys.forEach(key => {
          delete params.search[key[1]];
        });

        if (this.keywords.value) {
          params.search[this.keywords.currentKey] = this.keywords.value;
        }

        return $goodsAudit.list({
          page_no: params.pageIndex,
          page_size: params.pageSize,
          ...params.search,
          sort: params.sort.name ? `${params.sort.name}_${params.sort.order}` : 'def_asc'
        }).then(resp => {
          this.loading = false;
          return resp;
        });
      }
    });
  }

  reloadTable(currentTab) {
    this.tableProxy.rebuildLayout?.();
    this.tableProxy.reset(true);
    this.keywords.value = '';
    this.tableProxy.search.formData.goods_source = currentTab;
    this.tableProxy.request();
  }

  select(multiple = true, goods_id) {
    this.multiple = multiple;
    this.dialogProxy.display();

    if (!this.dialogTable) {
      this.dialogTable = $xTable.create({
        key: 'goods_id',
        onRequest(params: XTableStdRequestParams): Promise<XTableStdResponse> {
          return $goodsAuditDetail.list({
            page_no: params.pageIndex,
            page_size: params.pageSize,
            goods_id: goods_id
          })
        }
      })
    } else {
      this.dialogTable.reset();
    }
  }

  batchAudit() {
    if (this.tableProxy.exportData().length === 0) {
      this.$message.error('请勾选商品')
    } else {
      this.auditDialog.display();
    }
  }

  beforeConfirm() {
    return new Promise<boolean>(resolve => {
      const ids: any[] = []
      this.tableProxy.exportData().forEach(item => {
        ids.push(item.goods_id)
      })
      $GoodsAPI.batchAuditGoods(ids.toString(), this.goodsAuditForm).then(res => {
        this.$message.success('审核成功');
        this.tableProxy.request().then(() => resolve(true));
      });
    });
  }
}
