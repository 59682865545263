import * as $GoodsAPI from "@/api/goods";
import * as $API_Shop from "@/api/supplier";

export interface GoodsAuditParams {
  page_no?: number;
  page_size?: number;
  is_auth?: number;
  goods_sn?: string;
  goods_name?: string;
  goods_ptype?: string;
  market_enable?: string;
  low_mktprice?: number; // 市场价
  high_mktprice?: number;
  low_supprice?: number; // 成本价
  high_supprice?: number;
  firstCategory?: number; // 一级分类
  secondCategory?: number; // 二级分类
  threeCategory?: number; // 三级分类
  profit_min?: number; // 毛利率
  profit_max?: number;
  seller_name?: string;
  sort?: string;
}

export class GoodsItemProxy {
  thumbnail: string;
  goods_id: number;
  good_sn: number;
  goods_name: string;
  specsName: string; // 规格名称
  cost: number;
  original_price: number;
  price: number;
  mktprice: number;
  manager_profit: number;
  platform_profit: number;
  quantity: number;
  buy_count: number;
  seller_name: string;
  create_time: number;
  cat_name: string;
  constructor(public raw: any) {
    this.goods_id = raw.goods_id;
    this.thumbnail = raw.thumbnail;
    this.cat_name = raw.cat_name;
    this.good_sn = raw.good_sn;
    this.goods_name = raw.goods_name;
    this.specsName = raw.specs;
    this.cost = raw.cost;
    this.original_price = raw.original_price;
    this.price = raw.price;
    this.mktprice = raw.mktprice;
    this.manager_profit = raw.manager_profit;
    this.platform_profit = raw.platform_profit;
    this.quantity = raw.quantity;
    this.buy_count = raw.buy_count;
    this.seller_name = raw.seller_name;
    this.create_time = raw.create_time;
  }

  edit() {
    const { $router } = window["app"];
    return new Promise((resolve) => {
      $router.push({
        name: "goodsAuditDetail",
        query: {
          goodsid: this.goods_id,
          isdraft: 1,
        } as any,
      });
      resolve(true);
    });
  }
}

class GoodsAuditService {
  list(params: GoodsAuditParams) {
    return $GoodsAPI.getGoodsList(params).then((resp) => {
      resp.data = resp.data.map((d) => new GoodsItemProxy(d));
      return resp;
    });
  }
}

export interface GoodsAuditDetailParams {
  goods_id?: number;
  log_type?: number;
  page_no?: number;
  page_size?: number;
}

export class GoodsAuditDetailItemProxy {
  change_info: string;
  create_time: number;
  operator_name: string;

  constructor(public raw: any) {
    this.change_info = raw.change_info;
    this.create_time = raw.create_time;
    this.operator_name = raw.operator_name;
  }
}

class GoodsAuditDetailService {
  list(params: GoodsAuditDetailParams) {
    return $GoodsAPI.getChange(params).then((resp) => {
      resp.data = resp.data.map((d) => new GoodsAuditDetailItemProxy(d));
      return resp;
    });
  }
}

export interface SellerParams {
  shop_type: number;
  page_no: number;
  page_size: number;
}

export class SellerItemProxy {
  shop_name: string;
  shop_id: number;

  constructor(public raw: any) {
    this.shop_name = raw.shop_name;
    this.shop_id = raw.shop_id;
  }
}

class SellerService {
  list(params: SellerParams) {
    return $API_Shop.getShopList(params).then((resp) => {
      resp.data = resp.data.map((d) => new SellerItemProxy(d));
      return resp;
    });
  }
}

export const $goodsAudit = new GoodsAuditService();
export const $goodsAuditDetail = new GoodsAuditDetailService();
export const $sellerService = new SellerService();
