import * as $GoodsCategoryApi from '@/api/category';

export class GoodsCategoryProxy {
  children: GoodsCategoryProxy[] = [];
  props: any;

  static map: any = {};

  constructor(
    public id = 0,
    public label = '',
    public value = '',
    public level = 0,
    public leaf = level === 3,
  ) {
    if (this.id === 0) {
      this.load();
      this.props = {
        lazy: true,
        emitPath: false,
        checkStrictly: true,
        lazyLoad: (node, resolve) => {
          node.data.load().then(resolve);
        },
      };
    }
    if (this.leaf) this.children = null!;
  }

  load() {
    if (this.leaf || this.children.length) return Promise.resolve(this.children);

    return $GoodsCategoryApi.getCategoryChildren(this.id).then(resp => {
      this.children = resp.map(item => {
        const proxy = new GoodsCategoryProxy(
          item.category_id,
          item.name,
          item.category_path,
          this.level + 1,
        );
        GoodsCategoryProxy.map[item.category_path] = proxy;
        return proxy;
      }).sort((a, b) => a.id - b.id);
      return this.children;
    });
  }

  change(path) {
    GoodsCategoryProxy.map[path].load();
  }
}

class JDGoodsCategoryProxy {
  categories: any[] = [];

  private rebuildData(data: any[]) {
    return data.map(item => {
      if (item.children.length > 0) {
        item.children = this.rebuildData(item.children);
      } else {
        item.leaf = true;
      }
      return {
        label: item.name,
        value: item.cat_id,
        leaf: item.leaf,
        children: !!item.children.length && item.children,
      };
    }).sort((a, b) => a.value - b.value);
  }

  constructor() {
    // $GoodsApi.getJDGoodsCategoryLevelList('2').then(resp => {
    //   this.categories = this.rebuildData(resp);
    // });
  }
}

export const $goodsCategory = {
  JD: new JDGoodsCategoryProxy(),
  create: () => new GoodsCategoryProxy(),
};
